//css
import { NavbarContainer, BackToNav } from "./styles";

//images
import Logo from "../../images/logo.png";

//react icons
import { BsArrowUpShort } from "react-icons/bs";

//hooks
import { useEffect, useState } from "react";

//components
import { NavLink } from "react-router-dom";
import { useRef } from "react";

const Navbar = () => {
  const ref = useRef();

  const [hideNav, setHideNav] = useState(false);
  useEffect(() => {
    window.addEventListener("scroll", () => {
      window.pageYOffset >= 260 ? setHideNav(true) : setHideNav(false);
    });
  });

  const backToMenu = () => {
    window.scrollTo(0, 0);
  };

  return (
    <NavbarContainer ref={ref}>
      <BackToNav hideNav={hideNav} onClick={backToMenu}>
        <p>Voltar ao menu</p>
        <BsArrowUpShort />
      </BackToNav>
      <img src={Logo} alt="Misenscene Logo" />
      <ul>
        <li>
          <NavLink to="/">Início</NavLink>
        </li>
        <li>
          <NavLink to="/schedule">Programação</NavLink>
        </li>
        <li>
          <NavLink to="/schools">Teatro nas Escolas</NavLink>
        </li>
        <li>
          <NavLink to="/courses">Cursos</NavLink>
        </li>
        <li>
          <NavLink to="/animation-party">Animação de Festas</NavLink>
        </li>
      </ul>
    </NavbarContainer>
  );
};

export default Navbar;
