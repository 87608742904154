//css
import { ParagraphPatern } from "../../App";
import { InTheatersContainer } from "./styles";

const InTheaters = ({
  img,
  title,
  synopsis,
  date,
  hour,
  place,
  subject,
  direction,
  production,
  cast,
  link,
  moreInfo,
  school,
  assistence,
  rating,
  custom,
  customTop,
}) => {
  return (
    <InTheatersContainer>
      <img src={img} alt={title} />
      <h3>{title}</h3>
      {customTop &&
        customTop.length > 0 &&
        customTop.map((el) => {
          return <ParagraphPatern>{el.value}</ParagraphPatern>;
        })}
      {synopsis && <ParagraphPatern>{synopsis}</ParagraphPatern>}
      {date && <ParagraphPatern>{date}</ParagraphPatern>}
      {hour && <ParagraphPatern>Horário(s): {hour}</ParagraphPatern>}
      {place && <ParagraphPatern>Local: {place}</ParagraphPatern>}

      {direction && (
        <ParagraphPatern>Direção e Roteiro: {direction}</ParagraphPatern>
      )}
      {assistence && (
        <ParagraphPatern>Assistente de direção: {assistence}</ParagraphPatern>
      )}
      {cast && <ParagraphPatern>Elenco: {cast}</ParagraphPatern>}
      {production && <ParagraphPatern>Produção: {production}</ParagraphPatern>}
      {moreInfo && <ParagraphPatern>{moreInfo}</ParagraphPatern>}
      {rating && (
        <ParagraphPatern>Classificação Indicativa: {rating}</ParagraphPatern>
      )}
      {custom &&
        custom.length > 0 &&
        custom.map((el) => {
          return (
            <ParagraphPatern>
              {el.title}: {el.value}
            </ParagraphPatern>
          );
        })}
      {!link && !school && (
        <a
          href={`https://api.whatsapp.com/send?phone=5553981119125&text=Olá, Gostaria de adquirir ingressos para a peça: ${subject}`}
          target="_blank"
          rel="noreferrer"
        >
          Adquirir Ingressos
        </a>
      )}
      {link === "whats_poa" && !school && (
        <a
          href={`https://api.whatsapp.com/send?phone=5553981119125&text=Olá, Gostaria de adquirir ingressos para a peça: ${subject}`}
          target="_blank"
          rel="noreferrer"
        >
          Adquirir Ingressos
        </a>
      )}
      {!link && school && (
        <a
          href={`https://api.whatsapp.com/send?phone=5553981119125&text=Olá, gostaria de saber mais sobre a peça para as escolas: ${title}.`}
          target="_blank"
          rel="noreferrer"
        >
          Saber Mais
        </a>
      )}
      {link &&
        link !== "whats_poa" &&
        link !== "cursos_sls" &&
        link !== "cursos_poa" && (
          <a href={link} target="_blank" rel="noreferrer">
            Adquirir Ingressos
          </a>
        )}
      {link && link === "cursos_sls" && (
        <a
          href={`https://api.whatsapp.com/send?phone=5553981119125&text=Olá, Gostaria de saber mais informações sobre o curso ${subject}`}
          target="_blank"
          rel="noreferrer"
        >
          Saber Mais
        </a>
      )}

      <span></span>
    </InTheatersContainer>
  );
};

export default InTheaters;
