import styled from "styled-components";

export const ScheduleContainer = styled.div``;

export const TheatersSection = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1em 0 2em;

  h4 {
    font-size: 23px;
    margin: 0;
    color: black;
  }
`;

export const City = styled.h3`
  margin: 20px 0;
  text-transform: uppercase;
  font-size: 26px;
  color: #aa0000;
  text-align: center;
`;
