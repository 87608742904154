import { SchoolsContainer, TheatersSection, /* City */ } from "./styles";

import img from "../../images/teatroescola.png";
import { ParagraphPatern, TitleSectionsPatern } from "../../App";
import Contacts from "../../components/Contacts";
import InTheaters from "../../components/InTheaters";

//images
/* import aladdin from "../../imagesTheaters/aladdin.png"; */
import chapeuzinho_vermelho from "../../imagesTheaters/chapeuzinho_vermelho.png";
/* import madagascar_poa from "../../imagesTheaters/madagascar_poa.png"; */
/* import pinoquio from "../../imagesTheaters/pinoquio.png"; */
import os_tres_porquinhos from "../../imagesTheaters/os_tres_porquinhos.png";
//import resgate_de_natal from "../../imagesTheaters/resgate_de_natal.jpg"
import aurora2 from "../../imagesTheaters/aurora2.png";
//import frozen from "../../imagesTheaters/frozen.png";
//import branca_de_neve_leitura from "../../imagesTheaters/branca_de_neve_leitura.jpg";
import escolinha from "../../imagesTheaters/escolinha.jpg";
//import joao_e_maria from "../../imagesTheaters/joaoemaria.jpg";
//import wandinha from "../../imagesTheaters/wandinha.jpg";
/* import zodiaco from "../../imagesTheaters/zodiaco.jpg"; */
/* import maisAlgumaCoisa from "../../imagesTheaters/maisAlgumaCoisa.png"; */
import wandinha2 from "../../imagesTheaters/wandinha2.jpg";

const Schools = () => {
  return (
    <SchoolsContainer>
      <img src={img} alt="Teatro nas Escolas" />
      <TitleSectionsPatern>Descrição</TitleSectionsPatern>
      <ParagraphPatern>
        A fim de proporcionar o contato das crianças e jovens com a arte
        teatral, a Misenscene Arte e Cultura desenvolve o projeto Teatro nas
        Escola. Trabalhamos com peças voltadas desde a Educação Infantil até o
        Ensino Médio e levamos para a sua escola.
      </ParagraphPatern>
      <TheatersSection>
        <InTheaters
          img={os_tres_porquinhos}
          title="Os Três Porquinhos"
          synopsis="A peça é uma adaptação do clássico infantil, que conta a história de três porquinhos que deixam a casa de sua mãe para viverem suas vidas independentes, porém dois desses porquinhos são muito preguiçosos e só querem saber de se divertir, enquanto o outro só trabalha. Eles acabam encontrando um Lobo que estava quase saindo de férias, mas que muda os planos ao ser provocado pelos porquinhos."

          hour="A combinar"
          moreInfo="Indicado desde a Educação Infantil até o 5º ano do Ensino Fundamental."
          school={true}
        />
        <InTheaters
          img={chapeuzinho_vermelho}
          title="Chapeuzinho Vermelho"
          synopsis="A peça é uma adaptação do clássico infantil, que conta a história de uma menina que veste uma capa vermelha, presente de sua amada avó. Um dia a vovó fica doente e a menina vai visita-la, porém ela encontra um Lobo Mau no caminho, que engana a menina e chega primeiro na casa da vovó. O texto tem uma linguagem leve e cômica, com o foco direcionado ao público infantil."

          hour="A combinar"
          moreInfo="Indicado desde a Educação Infantil até o 5º ano do Ensino Fundamental."
          school={true}
        />
        <InTheaters
          img={aurora2}
          title="Aurora e O Mistério Dos Sapatos"
          synopsis="O Rei Stefan era muito protetor, todas as noites ele trancava a porta do quarto de sua filha Aurora, com medo dos perigos que pudessem lhe ocorrer. Um dia o rei começou a reparar em algo diferente, todas as manhãs os sapatos da princesa estavam gastos sem nenhuma explicação. Ele então resolve dar um pote de ouro para quem conseguir resolver o mistério dos sapatos de sua filha."

          hour="A combinar"
          moreInfo="Indicado desde a Educação Infantil até o 5º ano do Ensino Fundamental."
          school={true}
        />
        <InTheaters
          img={escolinha}
          title="Escolinha do Professor Etelvino"
          synopsis="Inspirados em clássicos como A Escolinha do Professor Raimundo e a Escolinha do Chaves, a peça pretende divertir o público com personagens hilários, fazendo todos lembrarem de cenas clássicas em uma sala de aula."

          hour="A combinar"
          moreInfo="Indicado a partir do 6º ano do Ensino Fundamental até o Ensino Médio e Técnico."
          school={true}
        /><InTheaters
          img={wandinha2}
          title="Wandinha"
          synopsis="Depois de ser expulsa de várias escolas, Wandinha Addams é transferida para Nunca Mais, a instituição dos excluídos em que seus pais estudaram. Lá começam a acontecer uma série de ataques, Wandinha então resolve permanecer na escola e investigar os mistérios desses estranhos acontecimentos!"

          hour="A combinar"
          moreInfo="Indicado para todos os públicos"
          school={true}
        />
      </TheatersSection>
      <Contacts subject={"Teatro nas Escolas"} />
    </SchoolsContainer>
  );
};

export default Schools;
