//css
import { ContactsContainer } from "./styles";
import { TitleSectionsPatern } from "../../App";

import emailjs from "@emailjs/browser";

//icons
import { BsWhatsapp, BsInstagram } from "react-icons/bs";
import { AiOutlineMail } from "react-icons/ai";
import { useState } from "react";
import { useEffect } from "react";

const Contacts = ({ subject }) => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [number, setNumber] = useState("");
  const [msg, setMsg] = useState("");

  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState("");
  const [error, setError] = useState("");

  const resetMessage = () => {
    setTimeout(() => {
      setError("");
      setSuccess("");
    }, 2000);
  };

  useEffect(() => {
    resetMessage();
  }, [error, success]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    const templateParans = {
      from_name: name,
      from_email: email,
      from_message: msg,
      from_number: number,
      from_subject: subject || "",
    };

    await emailjs
      .send(
        "service_3jvw7a9",
        "template_vxsefv5",
        templateParans,
        "2lDKer35MXvDM1B7p"
      )
      .then(() => {
        setSuccess("Email enviado com sucesso!");
        setName("");
        setEmail("");
        setNumber("");
        setMsg("");
      })
      .catch(() => {
        setError("Algo deu errado com o envio, por favor tente mais tarde!");
      });
    setLoading(false);
  };

  return (
    <ContactsContainer>
      <TitleSectionsPatern>Entre em contato</TitleSectionsPatern>
      <section>
        <div>
          <a
            href={`https://api.whatsapp.com/send?phone=5553981119125&text=Olá, queria informações sobre: ${subject}`}
            target={"_blank"}
            rel="noreferrer"
          >
            <BsWhatsapp /> (53)9-8111.9125
          </a>
          <span></span>
          <p>
            <AiOutlineMail /> misensceneartecultura@&#8203;gmail.com
          </p>
          <p>
            <BsInstagram />{" "}
            <a
              href="https://www.instagram.com/misensceneartecultura/"
              rel="noreferrer"
              target="_blank"
            >
              @misensceneartecultura
            </a>
          </p>
        </div>

        <form onSubmit={handleSubmit}>
          <input
            type="text"
            placeholder="Nome"
            required
            value={name || ""}
            onChange={(e) => setName(e.target.value)}
          />
          <input
            type="text"
            placeholder="Telefone"
            required
            value={number || ""}
            onChange={(e) => setNumber(e.target.value)}
          />
          <input
            type="email"
            placeholder="Email"
            required
            value={email || ""}
            onChange={(e) => setEmail(e.target.value)}
          />
          <textarea
            rows={6}
            required
            placeholder="Digite sua mensagem..."
            value={msg || ""}
            onChange={(e) => setMsg(e.target.value)}
          />
          <input type="submit" value="Enviar" />
          {error && <p>{error}</p>}
          {success && <p>{success}</p>}
          {loading && <p>Carregando...</p>}
        </form>
      </section>
    </ContactsContainer>
  );
};

export default Contacts;
