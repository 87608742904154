import styled, { createGlobalStyle } from "styled-components";

// components
import Navbar from "./components/Navbar";
import Footer from "./components/Footer";
//hooks
import { Routes, Route, BrowserRouter } from "react-router-dom";

//pages
import Home from "./pages/Home";
import Schedule from "./pages/Schedule";
import Schools from "./pages/Schools";
import Courses from "./pages/Courses";
import AnimationParty from "./pages/AnimationParty";

const desktop = 920;

const GlobalStyle = createGlobalStyle`
  body{
    font-family: "League Spartan", sans-serif;
  }
  
  *{
    margin: 0;
    padding: 0;
  }
`;

export const TitleSectionsPatern = styled.h2`
  background-color: #010080;
  width: 100%;
  text-align: center;
  padding: 0.3em 0 0.11em;
  text-transform: uppercase;
  color: #fffe00;
  font-size: 2em;
  word-wrap: break-word;

  @media (min-width: ${desktop}px) {
    width: 70%;
    margin: 0 auto;
  }
`;

export const ParagraphPatern = styled.p`
  margin: 20px auto;
  width: 100%;
  text-align: justify;
  color: #4c4c4c;
  font-size: 20px;
  line-height: 1.8em;
`;

function App() {
  return (
    <div>
      <GlobalStyle />
      <BrowserRouter>
        <Navbar />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/schedule" element={<Schedule />} />
          <Route path="/schools" element={<Schools />} />
          <Route path="/courses" element={<Courses />} />
          <Route path="/animation-party" element={<AnimationParty />} />
        </Routes>
        <Footer />
      </BrowserRouter>
    </div>
  );
}

export default App;
