import styled from "styled-components";

const desktop = 920;

export const NavbarContainer = styled.nav`
  display: flex;
  background-color: white;
  width: 100%;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  min-height: 260px;
  overflow: hidden;
  transition: max-height 0.2s;

  img {
    width: 100%;
    margin: 10px 0 15px;
  }

  ul {
    list-style: none;
    text-align: center;
    padding: 0;
    li {
      margin-bottom: 1.5em;

      a {
        padding-bottom: 0.5em;
        border-bottom: 1px solid #363636;
        color: black;
        font-size: 20px;
        text-decoration: none;

        &.active {
          color: #aa0000;
          border-color: #aa0000;
          border-bottom: 3px solid;
        }

        &:hover {
          color: #aa0000;
          border-color: #aa0000;
        }
      }
    }
  }

  @media (min-width: ${desktop}px) {
    img {
      width: 70%;
    }

    ul {
      width: 80%;
      max-width: 700px;
      display: flex;
      flex-direction: row;
      justify-content: space-evenly;
    }
  }
`;

export const BackToNav = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  transition: top 0.4s;
  max-width: 50px;
  overflow: hidden;
  height: 50px;
  border-radius: 10px;
  background-color: #010080;
  color: white;
  position: fixed;
  z-index: 1;
  top: ${(props) => (props.hideNav ? "10px" : "-100px")};
  right: 20px;
  cursor: pointer;

  svg {
    width: 50px;
    height: 50px;
    font-size: 100px;
  }

  P {
    margin-left: 1em;
    display: none;
    font-size: 18px;
    overflow: hidden;
  }

  @media (min-width: ${desktop}px) {
    &:hover {
      max-width: 200px;
      p {
        display: block;
      }
    }
  }
`;
