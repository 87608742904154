import styled from "styled-components";

export const FooterContainer = styled.footer`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  margin-top: 3em;
  height: 100px;
  background-color: #aa0000;

  p {
    color: white;
    font-size: 20px;
    text-align: center;
  }
`;
