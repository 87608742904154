import styled from "styled-components";

const desktop = 920;

export const CoursesContainer = styled.div`
  display: flex;
  flex-direction: column;
  img {
    width: 100%;
  }

  p {
    width: 70%;
    margin-bottom: 0;
  }

  h3 {
    margin: 0.7em 0;
    text-transform: uppercase;
    font-size: 26px;
    color: #aa0000;
    text-align: center;
  }

  @media (min-width: ${desktop}px) {
    img {
      width: 70%;
      margin: 20px auto;
    }
  }
`;

export const TheatersSection = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1em 0 2em;

  p {
    width: 100%;
  }

  img {
    width: 100%;
  }

  h4 {
    font-size: 23px;
    margin: 0;
    color: black;
  }
`;

export const City = styled.h3`
  margin: 20px 0;
  text-transform: uppercase;
  font-size: 26px;
  color: #aa0000;
  text-align: center;
`;

