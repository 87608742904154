//css
import { HomeContainer, HeaderMisenscene, AboutMisenscene } from "./styles";
import { ParagraphPatern, TitleSectionsPatern } from "../../App";

//components
import Contacts from "../../components/Contacts";
import photo1 from "../../images/photo1.png";
import photo2 from "../../images/photo2.png";
import photo3 from "../../images/photo3.png";
import photo4 from "../../images/photo4.png";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const Home = () => {
  const settings = {
    dots: true,
    infinite: true,
    arrows: false,
    speed: 300,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 4000,
    draggable: false,
    responsive: [
      {
        breakpoint: 920,
        setting: {
          dots: true,
          arrow: false,
        },
      },
    ],
  };
  return (
    <HomeContainer>
      <HeaderMisenscene>
        <Slider {...settings}>
          <img src={photo1} alt="photo1" />
          <img src={photo2} alt="photo2" />
          <img src={photo3} alt="photo3" />
          <img src={photo4} alt="photo4" />
        </Slider>
      </HeaderMisenscene>
      <AboutMisenscene>
        <TitleSectionsPatern>Sobre a Misenscene</TitleSectionsPatern>
        <ParagraphPatern>
          A Misenscene teve origem em novembro de 2013, com estreia no dia 1º de
          Maio de 2014, é uma empresa voltada a Arte e a Cultura, com enfoque
          principalmente na arte teatral. Trabalhamos com montagens de
          espetáculos infantis e adultos, e ministramos cursos de teatro para
          todos os públicos.
        </ParagraphPatern>
      </AboutMisenscene>
      <Contacts subject={"A Misenscene"} />
    </HomeContainer>
  );
};

export default Home;
