//css
import { AnimationPartyContainer } from "./styles";

//images
import img from "../../images/animation-party.png";
import { ParagraphPatern, TitleSectionsPatern } from "../../App";
import Contacts from "../../components/Contacts";

const AnimationParty = () => {
  return (
    <AnimationPartyContainer>
      <img src={img} alt="Animação de Festas" />
      <TitleSectionsPatern>Descrição</TitleSectionsPatern>
      <ParagraphPatern>
        O animador irá para sua festa ou evento caracterizado com algum
        personagem e realizará jogos e brincadeiras com as crianças no período
        de tempo combinado. Podendo ter contação de histórias, teatro de
        fantoches, pintura em rosto e/ou apresentação de cenas.
      </ParagraphPatern>
      <TitleSectionsPatern>Atividades</TitleSectionsPatern>
      <h3>Jogos e Brincadeiras</h3>
      <ParagraphPatern>
        Os animadores irão conduzir jogos e brincadeiras com as crianças.
      </ParagraphPatern>
      <h3>Contação de Histórias</h3>
      <ParagraphPatern>
        O animador irá contar histórias variadas para as crianças.
      </ParagraphPatern>
      <h3>Teatro de Fantoches</h3>
      <ParagraphPatern>
        Apresentação de pequenas peças teatrais com fantoches.
      </ParagraphPatern>
      <h3>Pinturas em Rosto</h3>
      <ParagraphPatern>
        O animador irá maquiar as crianças com desenhos artísticos.
      </ParagraphPatern>
      <h3>Apresentação de Cenas</h3>
      <ParagraphPatern>
        Os personagens poderão apresentar pequenas cenas referentes a sua
        história.
      </ParagraphPatern>
      <Contacts subject={"Animação de Festas"} />
    </AnimationPartyContainer>
  );
};

export default AnimationParty;
