import { /* City,  */CoursesContainer, /* TheatersSection */ } from "./styles";

import img from "../../images/cursos.png";
import { ParagraphPatern, TitleSectionsPatern } from "../../App";
import Contacts from "../../components/Contacts";
// import InTheaters from "../../components/InTheaters";
// import circo from "../../imagesTheaters/circo.jpg";
// import cursos2 from "../../images/cursos2.jpg";
const Courses = () => {
  return (
    <CoursesContainer>
      <img src={img} alt="Misenscene Cursos" />
      <TitleSectionsPatern>Descrição</TitleSectionsPatern>
      <ParagraphPatern>
        As aulas de teatro auxiliam no desenvolvimento do indivíduo em muitos
        aspectos, individuais e coletivos, onde aprendem a desenvolver sua
        criatividade, expressões artísticas e o trabalho em grupo, além de
        ajudar na autoestima e empatia. <br></br>
        Realizamos cursos para crianças, jovens e adultos, todas as aulas são
        preparadas de maneiras distintas, dependendo da faixa etária dos alunos
        e como elas se adaptam as propostas. Nelas estimulamos para que o aluno
        passe a desenvolver a atenção, concentração, observação e criatividade,
        além de conhecer a linguagem teatral, que é nosso principal objetivo.
      </ParagraphPatern>
      <h3>Em breve novas turmas</h3>
      <Contacts subject={"Cursos"} />
    </CoursesContainer>
  );
};

export default Courses;
