//css
import { ScheduleContainer, TheatersSection, City } from "./styles";
import { TitleSectionsPatern } from "../../App";

//components
import InTheaters from "../../components/InTheaters";

//images
/* import aladdin from "../../imagesTheaters/aladdin.png"; */
// import chapeuzinho_vermelho from "../../imagesTheaters/chapeuzinho_vermelho.png";
/* import madagascar_poa from "../../imagesTheaters/madagascar_poa.png"; */
//import pinoquio from "../../imagesTheaters/pinoquio.png";
// import os_tres_porquinhos from "../../imagesTheaters/os_tres_porquinhos.png";
//import resgate_de_natal from "../../imagesTheaters/resgate_de_natal.jpg";
//import ferias from "../../imagesTheaters/ferias_lobomau.jpg";
//import funeral from "../../imagesTheaters/funeral.jpg";
/* import aurora2 from "../../imagesTheaters/aurora2.png"; */
// import frozen from "../../imagesTheaters/frozen.png";
//import branca_de_neve_leitura from "../../imagesTheaters/branca_de_neve_leitura.jpg";
//import escolinha from "../../imagesTheaters/escolinha.jpg";
// import escolinha_2 from "../../imagesTheaters/escolinha_2.jpg";
// import shrek from "../../imagesTheaters/shrek.jpg";
// import circo from "../../imagesTheaters/circus.jpg";
// import maleta from "../../imagesTheaters/maleta.jpg";
//import joao_e_maria from "../../imagesTheaters/joaoemaria.jpg";
//import wandinha from "../../imagesTheaters/wandinha.jpg";
/* import wandinha2 from "../../imagesTheaters/wandinha2.jpg"; */
// import ultimo_artista from "../../imagesTheaters/ultimo_artista.jpg";
import zodiaco from "../../imagesTheaters/zodiaco.jpg";
// import maisAlgumaCoisa from "../../imagesTheaters/maisAlgumaCoisa.png";
/* import rei_leao from "../../imagesTheaters/rei_leao.jpg"; */

//hooks
import { useLocation } from "react-router-dom";
import { useEffect } from "react";
import Contacts from "../../components/Contacts";

const Schedule = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <ScheduleContainer>
      <TheatersSection>
        <TitleSectionsPatern>Em cartaz</TitleSectionsPatern>
        <City>São Lourenço do Sul</City>
        <City>Porto Alegre</City>
        <h2>Em breve</h2>
        <InTheaters
          img={zodiaco}
          title="Zodíaco – O Casamento?"
          synopsis="O que aconteceria em um possível casamento entre os signos de Áries e Escorpião? Com uma madrinha Leonina, um tio Sagitariano, uma irmã Canceriana e uma mãe Capricorniana? A trama se desenrola nos preparativos e no casamento de uma Ariana com um Escorpiano, em que cada personagem da história é representado por um signo do zodíaco, trazendo de forma cômica e divertida algumas das caracterizas mais comentadas de cada um."
          date="Dia: 07 de setembro de 2024 – Sábado"
          hour="20:30"
          place="Teatro Zé Rodrigues (Rua Antônio Joaquim Mesquita, 500)."
          subject="Zodíaco – O Casamento?"
          direction="Lucas Lopes"
          cast="Arthur, Diandra Tavares, Isabelle Schüler, Laura Marques, Leonardo Ermel, Líneke Machado, Lola Flores, Lucas Lopes, Luis Couto, Marco Marchessano, Maria Eduarda Lopes, Marina Oz e Martina Alves."
          rating="+14"
        />
      </TheatersSection>
      <Contacts subject={"Programação"} />
    </ScheduleContainer>
  );
};

export default Schedule;
