import styled from "styled-components";

const desktop = 920;

export const InTheatersContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 90%;
  max-width: 500px;
  background-color: white;
  border-radius: 20px;
  row-gap: 1.5em;

  img {
    width: 100%;
    border-radius: 10px;
  }

  p {
    margin: 0;
  }

  h3 {
    font-size: 32px;
    text-transform: uppercase;
    color: #010080;
    text-align: center;
  }

  a {
    text-decoration: none;
    border: none;
    font-size: 24px;
    background-color: #2b2ab8;
    padding: 0.6em;
    color: white;
    opacity: 0.8;
    transition: opacity 0.4s;
    cursor: pointer;

    &:hover {
      opacity: 1;
    }
  }

  span {
    display: block;
    width: 70%;
    height: 4px;
    background-color: #aa0000;
    margin: 2em 0;
    border-radius: 5px;
  }

  @media (min-width: ${desktop}px) {
    max-width: 700px;
  }
`;
