import styled from "styled-components";

const desktop = 920;

export const ContactsContainer = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  row-gap: 4em;
  width: 100%;

  section {
    display: flex;
    flex-direction: column;
    width: 100%;
    row-gap: 40px;
  }

  * {
    margin: 0 auto;
  }

  div {
    display: flex;
    flex-direction: column;
    width: 75%;
    p,
    a {
      word-wrap: break-word;
      color: #363636;
      width: 100%;
      font-size: 20px;
      letter-spacing: 3%;
      line-height: 1.2em;
    }

    svg {
      font-size: 18px;
    }

    a {
      text-decoration: none;
      color: #aa0000;
      white-space: break-spaces;

      @media (min-width: ${desktop}px) {
        color: #363636;
        &:hover {
          color: #aa0000;
        }
      }
    }

    span {
      display: inline-block;
      width: 100%;
      max-width: 400px;
      height: 1px;
      background-color: #363636;
      margin: 10px 0;
    }
  }

  form {
    display: flex;
    flex-wrap: wrap;
    row-gap: 0.5em;
    column-gap: 4%;
    max-width: 400px;
    width: 90%;
    * {
      box-sizing: border-box;
    }
    input,
    textarea {
      border: none;
      border: 3px solid #010080;
      font-size: 18px;
      padding: 4% 5%;

      &:hover {
        border: 3% solid #010080;
      }
    }

    input:focus,
    textarea:focus {
      border: 3% solid #010080;
    }
    input:nth-of-type(1),
    input:nth-of-type(2) {
      width: 48%;
    }
    input:nth-of-type(3) {
      width: 100%;
    }
    textarea,
    input[type="submit"] {
      width: 100%;
    }
    input[type="submit"] {
      cursor: pointer;
      background-color: #010080;
      opacity: 0.8;
      color: white;
      transition: opacity 0.4s;

      &:hover {
        opacity: 1;
        border: 3px solid #010080;
      }
    }

    p {
      font-size: 18px;
      width: 100%;
      text-align: center;
    }
  }

  @media (min-width: ${desktop}px) {
    section {
      flex-direction: row;
      column-gap: 70px;
      width: 70%;
      justify-content: space-evenly;
      align-items: top;

      div {
        max-width: 500px;
        display: block;

        p {
          margin: 0 0 20px;
        }

        span {
          margin: 40px 0;
          max-width: 100%;
        }
      }
    }
  }
`;
