import styled from "styled-components";

const desktop = 920;

export const HomeContainer = styled.div``;

export const HeaderMisenscene = styled.section`
  margin: 20px auto 35px;
  @media (min-width: ${desktop}px) {
    width: 70%;
    overflow: visible;
    .slick-slide {
      img {
        height: 70vh;
        width: 100%;
        object-fit: cover;
        object-position: top;
      }
    }

    .slick-dots li button:before {
      font-size: 12px;
    }
  }
`;

export const AboutMisenscene = styled.div`
  p {
    width: 70%;
  }
`;
