import styled from "styled-components";

const desktop = 920;

export const AnimationPartyContainer = styled.div`
  display: flex;
  flex-direction: column;

  img {
    width: 100%;
  }

  P {
    width: 70%;
    margin-top: 5px;
  }

  h3 {
    margin: 20px 0 0;
    text-transform: uppercase;
    font-size: 26px;
    color: #aa0000;
    text-align: center;
  }

  @media (min-width: ${desktop}px) {
    h3 + p {
      text-align: center;
    }
    img {
      width: 70%;
      margin: 20px auto;
    }
  }
`;
